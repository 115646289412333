<!--
 * @Author: your name
 * @Date: 2021-12-22 16:24:35
 * @LastEditTime: 2021-12-22 18:43:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \fin\src\views\carrier-settlement\logisticscost-management\index.vue
-->
<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="对账单" name="first">
        <el-form ref="form" :model="form" label-width="100px" style="display: flex">
          <div class="el-lt" style="width: 85%;">
            <el-row :span="24" class="row-input" :class="{ 'show-row': showRow1 }">
              <el-col :span="8">
                <el-form-item label="来源单号">
                  <el-input v-model="form.sourceNo" />
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="承运商">
                  <el-select v-model="form.carrierCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in newCarrierList"
                      :key="item.id"
                      :label="item.vendorName"
                      :value="item.vendorCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算主体">
                  <el-select
                    v-model="form.settleEntityCode"
                    style="width:100%;"
                    value-key="id"
                    placeholder="请选择"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in cargoOwnerNameList"
                      :key="item.cargoOwnerName"
                      :label="item.cargoOwnerName"
                      :value="item.cargoOwnerCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="结算状态">
                  <el-select v-model="form.settleStatus" value-key="id" clearable filterable style="width:100%;">
                    <el-option v-for="item in settleStatusList" :key="item.val" :label="item.label" :value="item.val" />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="对账状态">
                  <el-select v-model="form.auditStatus" style="width:100%;" value-key="id" clearable filterable>
                    <el-option v-for="item in auditStatusList" :key="item.val" :label="item.label" :value="item.val" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="发运单号">
                  <el-input v-model="form.shipNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账期截止日期">
                  <el-date-picker
                    v-model="form.value1"
                    type="daterange"
                    range-separator="~"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24">
                <!-- 单选应付单，已对账单不允许操作 -->
                <el-button type="primary" @click="handleSpecialReg('logistics',0)">物流对账登记</el-button>
                <el-button type="primary" @click="handleSpecialReg('tax',1)">关税对账登记</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row class="el-rt" style="width:25%">
            <el-col class="row-center">
              <el-button type="primary" :loading="tableLoading" @click="queryClick()">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
              <el-button type="text" @click="showRow1=!showRow1">{{
                showRow1 ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <Table
          ref="haveBillDataRef"
          :columns="noReconcileColumns"
          :table-data="haveBillData"
          :page-obj="pager1"
          :loading="tableLoading"
        >
          <el-table-column slot="auditNo" label="对账单号" width="180">
            <template slot-scope="scope">
              <el-button type="text" :style="customStyle(scope.row.auditType)" @click="edit(scope.row,'view')">
                {{ scope.row.auditNo }}</el-button>
            </template>
          </el-table-column>
          <el-table-column slot="operate" label="操作">
            <template slot-scope="scope">
              <el-button v-if="scope.row.auditStatus==1" type="text" @click="edit(scope.row,'edit')">对账</el-button>
            </template>
          </el-table-column>
          <el-table-column slot="download" label="备注">
            <template slot-scope="scope">
              <el-button v-if="scope.row.filePath" type="text" @click="downloadByThoms(scope.row.filePath)">下载
              </el-button>
            </template>
          </el-table-column>
        </Table>
        <div class="block">
          <Paging :pager="pager1" end @update="pagerUpdate" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="已对账单" name="second">
        <el-form ref="noForm" :model="noForm" label-width="100px" style="display: flex" :rules="rules">
          <div class="el-lt" style="width: 88%;">
            <el-row :span="24" class="row-input" :class="{ 'show-row': showRow2 }">

              <el-col :span="8">
                <el-form-item label="结算主体" prop="settleEntityCode">
                  <el-select
                    v-model="noForm.settleEntityCode"
                    style="width:100%;"
                    value-key="id"
                    placeholder="请选择"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="item in cargoOwnerNameList"
                      :key="item.cargoOwnerName"
                      :label="item.cargoOwnerName"
                      :value="item.cargoOwnerCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="承运商" prop="carrierCode">
                  <el-select v-model="noForm.carrierCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in newCarrierList"
                      :key="item.id"
                      :label="item.vendorName"
                      :value="item.vendorCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="币种" prop="currencyCode">
                  <el-select v-model="noForm.currencyCode" value-key="id" clearable filterable style="width:100%;">
                    <el-option
                      v-for="item in _getAllCommodityDict('CURRENCY_TYPE')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="来源单号" :class="$i18n.locale">
                  <el-input v-model="noForm.sourceNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="付款状态" :class="$i18n.locale">
                  <el-select
                    v-model="noForm.paymentAmountStatus"
                    style="width:100%;"
                    filterable
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_LogisticsPaymentAmountStatusEnum')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="请款状态" :class="$i18n.locale">
                  <el-select
                    v-model="noForm.requestAmountStatus"
                    style="width:100%;"
                    filterable
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >
                    <el-option
                      v-for="item in _getAllCommodityDict('FIN_LogisticsRequestAmountStatusEnum')"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="费用片段" :class="$i18n.locale">
                  <el-select
                    v-model="noForm.feeSnippetCode"
                    style="width:100%;"
                    filterable
                    clearable
                    :placeholder="$t('page.selectPlaceholder')"
                  >
                    <el-option v-for="item in feeSnippetList" :key="item.val" :label="item.label" :value="item.val" />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="发运单号" :class="$i18n.locale">
                  <el-input v-model="noForm.shipNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="应付单号" :class="$i18n.locale">
                  <el-input v-model="noForm.payableNo" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="账期截止日期">
                  <el-date-picker
                    v-model="noForm.value1"
                    type="daterange"
                    range-separator="~"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24">
                <el-button type="primary" @click="showRequestFundsVisible(0)">费用请款</el-button>
                <el-button type="primary" @click="showRequestFundsVisible(1)">关税请款</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row class="el-rt" style="width: 15%">
            <el-col class="row-center">
              <el-button type="primary" @click="queryClick()">{{
                $t("page.search")
              }}</el-button>
              <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
              <el-button type="text" @click="showRow2=!showRow2">{{
                showRow2 ? $t("page.hide") : $t("page.expand")
              }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <Table
          ref="NoBillDataRef"
          :show-summary="true"
          :get-summaries="getSummaries"
          :expand="true"
          :columns="reconciledColumns"
          :table-data="NoBillData"
          :expand-columns="expandReconciledColumns"
          :page-obj="pager2"
          :loading="tableLoading"
        >
          <el-table-column slot="feeSnippet" label="费用片段" width="120">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.feeSnippet.split(',')" :key="index"> {{ item }}</div>
            </template>
          </el-table-column>
        </Table>
        <div class="block">
          <Paging :pager="pager2" end @update="pagerUpdateNoForm" />
        </div>
      </el-tab-pane>

    </el-tabs>
    <el-dialog title="发运单选择" :visible.sync="dialogVisible" width="80%" center :close-on-click-modal="false">
      <el-form :inline="true" :model="sendOrderForm" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="sendOrderForm.containerNo" placeholder="货柜号" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="sendOrderForm.ladingNo" placeholder="提单号" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="sendOrderForm.expectContainerNo" placeholder="预期货柜号" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="querySendOrderLoading" @click="querySendOrder()">查询</el-button>
        </el-form-item>
      </el-form>
      <Table
        ref="sendOrderDataRef"
        :columns="sendOrdersColumns"
        :table-data="sendOrdersDatas"
        :check="true"
        :loading="querySendOrderLoading"
        @handleSelectionChange="handleTemplateSelectionChange"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        <el-button type="primary" :loading="submitLoading" :disabled="templateSelections.length<1" @click="submit">确认
        </el-button>
      </span>
    </el-dialog>
    <RequestFunds v-model="requestFundsVisible" :form="noForm" @refresh="queryClick" />
  </div>
</template>

<script>
import { noReconcileColumns, reconciledColumns, sendOrdersColumns, expandReconciledColumns } from '@/utils/table-columns.js'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Paging'
import Table from '@/components/Table'
import { getcargoownerList, getVendorListByVendorType } from '@/api/select.js'
import RequestFunds from './components/requestfundsDialog'
import { reconciliationListByPage, listShipInfoSelectByPage, listBilledByPage, checkPaymentAmount, downloadByThoms } from '@/api/carriers'

export default {
  components: { Paging, Table, RequestFunds },
  mixins: [commodityInfoDict],
  data() {
    return {
      newCarrierList: [],
      auditStatusList: [],
      settleStatusList: [],
      feeSnippetList: [],
      submitLoading: false,
      querySendOrderLoading: false,
      auditType: '',
      requestFundsVisible: false,
      expandReconciledColumns,
      sendOrdersColumns,
      sendOrdersDatas: [],
      dialogVisible: false,
      offerMethodvisible: false,
      noReconcileColumns,
      reconciledColumns,
      tableLoading: false,
      createTimeStartOptions: {
        disabledDate: (time) => {
          if (this.form.endDate) {
            return time.getTime() > new Date(this.form.endDate).getTime()
          }
        }
      },
      createTimeEndOptions: {
        disabledDate: (time) => {
          if (this.form.startDate) {
            return time.getTime() < new Date(this.form.startDate).getTime()
          }
        }
      },
      regDialogvisible: false,
      cargoOwnerNameList: [],
      pager1: {
        current: 1,
        size: 20,
        total: 0
      },
      pager2: {
        current: 1,
        size: 20,
        total: 0
      },
      NoBillData: [],
      noForm: {
        settleEntityCode: '',
        currencyCode: '',
        carrierCode: '',
        value1: []

      },
      haveBillData: [],
      activeName: 'first',
      form: {
        settleEntityCode: '',
        carrierCode: '',
        value1: []
      },
      vendorOptions: [],
      showRow1: false,
      showRow2: false,
      multipleSelection: [],
      templateSelections: [],
      sendOrderForm: {},
      rules: {
        carrierCode: { required: true, message: '必填', trigger: 'blur' },
        settleEntityCode: { required: true, message: '必填', trigger: 'blur' },
        currencyCode: { required: true, message: '必填', trigger: 'blur' }
      }
    }
  },
  computed: {
    queryParams() {
      if (this.activeName === 'first') {
        const { value1 } = this.form
        const [startDate, endDate] = value1 || []
        return Object.assign({}, this.form, this.pager1, { startDate, endDate })
      } else {
        const { value1 } = this.noForm
        const [startDate, endDate] = value1 || []
        return Object.assign({}, this.noForm, this.pager2, { startDate, endDate })
      }
    }
  },
  created() {

  },
  mounted() {
    this._getcargoownerList()
    this._getVendorListByVendorType()
    this.queryClick()
    this.feeSnippetList = this._getAllCommodityDict('FIN_COST_FRAGMENT')
    this.settleStatusList = this._getAllCommodityDict('FIN_LogisticsSettleStatusEnum').filter(item => item.val + '' !== '100')
    this.auditStatusList = this._getAllCommodityDict('FIN_LogisticsAuditStatusEnum').filter(item => item.val + '' !== '0')
    this.feeSnippetList.push({ val: 'FYPD888', label: '预充值' })
  },
  methods: {
    customStyle(type) {
      if (type + '' === '2' || type + '' === '3') {
        return {
          color: '#606266',
          cursor: 'default '
        }
      }
    },
    async _getVendorListByVendorType() {
      const { datas } = await getVendorListByVendorType({ vendorType: '6' })
      this.newCarrierList = datas
    },
    showRequestFundsVisible(requestType) {
      this.noForm.requestType = requestType
      const obj = this.cargoOwnerNameList.find(item => item.cargoOwnerCode === this.noForm.settleEntityCode)
      this.noForm.settleEntity = obj ? obj.cargoOwnerName : ''
      this.$refs.noForm.validate(async valid => {
        if (valid) {
          const obj = Object.assign({}, this.queryParams, { requestType })
          const { code } = await checkPaymentAmount(obj)
          code === 0 ? this.requestFundsVisible = true : ''
        } else {
          this.$message.warning('请选择承运商，结算主体，币种')
        }
      })
    },
    download(url) {
      window.open(url, '_blank')
    },
    async downloadByThoms(httpUrlString) {
      const res = await downloadByThoms({ httpUrlString })
      const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream;charset=utf-8' }))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      const arr = httpUrlString.split('/')[httpUrlString.split('/').length - 1].split('.')
      const fileName = arr[0]
      link.setAttribute('download', fileName + '.' + arr[1])
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
    },
    async querySendOrder() {
      try {
        this.querySendOrderLoading = true
        const { carrierCode, settleEntityCode } = this.form
        if (carrierCode && settleEntityCode) {
          const params = Object.assign({}, this.sendOrderForm, { auditType: this.auditType, carrierCode, settleEntityCode })
          const { datas: records } = await listShipInfoSelectByPage(params)
          this.sendOrdersDatas = records
        }
      } finally {
        this.querySendOrderLoading = false
      }
    },
    async edit({ id: itemId, auditType }, flag) {
      if (auditType + '' === '2' || auditType + '' === '3') {
        return
      }
      this.auditType = auditType
      auditType + '' === '0' ? this.type = 'logistics' : this.type = 'tax'
      this.$router.push({
        path: 'reconcile',
        append: 'true',
        query: { type: this.type, flag, itemId, auditType: this.auditType }
      })
    },
    async submit() {
      if (this.templateSelections.length >= 1) {
        try {
          this.submitLoading = true
          const arr = this.templateSelections.map(item => { return { ...item, auditType: this.auditType } })
          this.$router.push({
            path: 'reconcile',
            append: 'true',
            query: { flag: 'edit', type: this.type, auditType: this.auditType, list: JSON.stringify(arr) }
          })
        } finally {
          this.submitLoading = false
        }
      }
    },
    showDialgTemplate() {
      // 先限制
      this.dialogVisible = true
    },
    handleTemplateSelectionChange(val) {
      this.templateSelections = val
    },
    handleChangeLogistics(row, type) {

    },
    async handleSpecialReg(type, auditType) {
      this.auditType = auditType
      // console.log(this.auditType)
      const { carrierCode, settleEntityCode } = this.form
      if (carrierCode && settleEntityCode) {
        this.type = type
        const params = Object.assign({}, { auditType, carrierCode, settleEntityCode })
        const { datas: records } = await listShipInfoSelectByPage(params)
        this.sendOrdersDatas = records
        this.dialogVisible = true
        // 进入发运单选择弹框
      } else {
        this.$message.warning('请选择选择承运商、结算主体')
        return
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['auditConfirmAmount', 'differenceFees', 'requestAlreadyAmount', 'paymentPendingAmount', 'requestPendingAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },

    queryClick() {
      this.activeName === 'first' ? this.pager1.current = 1 : this.pager2.current = 1
      this.handleQuery(this.queryParams)
    },
    async handleQuery() {
      if (this.activeName === 'first') {
        try {
          this.tableLoading = true
          const { code, datas: { pager, records }} = await reconciliationListByPage(this.queryParams)
          if (code === 0) {
            this.pager1 = pager
            this.haveBillData = records.map(item => {
              return {
                ...item, auditStatusI8: this.getDicLabel('FIN_LogisticsAuditStatusEnum', item.auditStatus),
                settleStatusI8: this.getDicLabel('FIN_LogisticsSettleStatusEnum', item.settleStatus),
                auditTypeI8: this.getDicLabel('FIN_LogisticsAuditTypeEnum', item.auditType)
              }
            })
          }
        } finally {
          this.tableLoading = false
        }
      } else {
        try {
          this.$refs.noForm.clearValidate()
          this.tableLoading = true
          const { code, datas: { pager, records }} = await listBilledByPage(this.queryParams)
          if (code === 0) {
            this.pager2 = pager
            this.NoBillData = records.map(item => {
              return {
                ...item, expandData: item.requestItemVOList.map(e => {
                  return {
                    ...e, paymentAmountStatusI8: this.getDicLabel('FIN_LogisticsPaymentAmountStatusEnum', e.paymentAmountStatus),
                    requestAmountStatusI8: e.requestAmountStatus === 0 ? '审批中' : e.requestAmountStatus === 1 ? '已通过' : e.requestAmountStatus === 2 ? '已拒绝' : e.requestAmountStatus === 3 ? '已撤销' : '已删除'
                  }
                }), paymentAmountStatusI8: this.getDicLabel('FIN_LogisticsPaymentAmountStatusEnum', item.paymentAmountStatus),
                requestAmountStatusI8: this.getDicLabel('FIN_LogisticsRequestAmountStatusEnum', item.requestAmountStatus)
              }
            })
          }
        } finally {
          this.tableLoading = false
        }
      }
    },
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    pagerUpdate(val) {
      this.pager1 = val
      this.handleQuery(this.queryParams)
    },
    pagerUpdateNoForm(val) {
      this.pager2 = val
      this.handleQuery(this.queryParams)
    },
    resetClick() {
      if (this.activeName === 'first') {
        this.form = this.$options.data.call(this).form
      } else {
        this.noForm = this.$options.data.call(this).noForm
      }
      this.queryClick()
    },
    handleClick(val) {
      if (val.name === 'second') {
        this.$refs.NoBillDataRef.$refs.table.clearSelection()
      }
      this.queryClick()
    },

    selectionChange(val) {
      this.multipleSelection = val
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-date-editor .el-range-input {
  width: 80px !important;
}
/deep/.el-dialog__title {
  font-weight: 500;
  font-family: normal;
  letter-spacing: 1px;
  font-size: 20px;
}
</style>
