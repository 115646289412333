<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 供应商请款弹框 -->
    <el-dialog :close-on-click-modal="false" :title="title+'请款'" :visible.sync="dialogVisible" width="90%" center>
      <el-form ref="defaultForm" :inline="true" :model="defaultForm" :rules="rules">
        <el-form-item label="请款总金额">
          <el-input v-model="defaultForm.requestAmount" placeholder="请输入请款总金额" type="number" disabled />
        </el-form-item>
        <el-form-item label="待冲抵总金额">
          <el-input v-model="defaultForm.requestPendingAmount" placeholder="待冲抵总金额" disabled type="number" />
        </el-form-item>
        <el-form-item label="收款账户" prop="receiveAccount">
          <el-select
            v-model="defaultForm.receiveAccount"
            placeholder="请选择"
            filterable
            :disabled="AccountList.length===1"
          >
            <el-option v-for="item in AccountList" :key="item.id" :label="item.cardNo" :value="item.cardNo" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="_submit('funds')">提交并请款</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        待冲抵
      </el-row>
      <el-form ref="offsetForm" :model="offsetForm" :rules="offsetForm.rules">
        <Table
          ref="offsetTable"
          :table-data="offsetForm.tableData1"
          :show-summary="true"
          :get-summaries="getSummaries"
          :columns="toBeOffsetColumns"
          :check="true"
          @select="select"
          @selectAll="selectAllPend"
          @handleSelectionChange="handlePendSelectionChange"
        >
          <el-table-column slot="qkje" label="冲抵金额" align="center" width="180" prop="requestAmount">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.requestAmount"
                style="width:100%"
                :controls="false"
                :max="0"
                :precision="2"
                :min="scope.row.requestPendingAmount"
                @blur="blurRequestAmount('pend',scope.row)"
              />
            </template>
          </el-table-column>
        </Table>
      </el-form>
      <el-row class="mb-1">
        待请款
      </el-row>
      <el-form ref="vendorForm" :inline="true" :model="vendorForm" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="vendorForm.payableNo" placeholder="应付单号" />
        </el-form-item>
        <el-form-item>
          <el-select v-model="vendorForm.feeSnippetCode" value-key="id" clearable filterable style="width:100%;">
            <el-option
              v-for="item in _getAllCommodityDict('FIN_COST_FRAGMENT')"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery(1)">搜索</el-button>
          <el-button type="primary" @click="resetClick">重置</el-button>
        </el-form-item>
      </el-form>

      <Table
        ref="multipleTable"
        :table-data="ruleForm.tableData1"
        :columns="pendingColumns"
        :check="true"
        :reserve-selection="true"
        :show-summary="true"
        :page-obj="pager"
        :get-summaries="getSummaries"
        @select="select"
        @selectAll="selectAll"
        @handleSelectionChange="handleSelectionChange"
      >
        <el-table-column slot="feeSnippet" label="费用片段" align="center" width="120">
          <template slot-scope="scope">
            <div v-for="(item,index) in scope.row.feeSnippet.split(',')" :key="index"> {{ item }}</div>
          </template>
        </el-table-column>
        <el-table-column slot="requestAmount" label="请款金额" align="center" width="160" prop="requestAmount">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.requestAmount"
              :max="scope.row.requestPendingAmount"
              style="width:100%"
              :controls="false"
              :min="0"
              :precision="2"
              @blur="blurRequestAmount('req',scope.row)"
              @wheel.native.prevent="stopScroll($event)"
            />
          </template>
        </el-table-column>
      </Table>
      <div class="block">
        <Paging :pager="pager" end :page-sizes="[50,100,200,500]" @update="pagerUpdate" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table'
import Paging from '@/components/Paging'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { listPaymentRequestByPage, paymentRequest } from '@/api/carriers.js'
import { toBeOffsetColumns, pendingColumns } from '@/utils/table-columns'
import { FloatAdd, FloatSub } from '@/utils/acc.js'
import { getVendorAccountListByVendorCode } from '@/api/select'
import { deepClone } from '@/utils'

export default {

  components: { Paging, Table },
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => { }
    }

  },
  data() {
    const validaterequstFundsAmount = (rule, value, callback) => {
      const reg = /^\d+.?\d{0,2}$/
      if (value === '') {
        callback(new Error('必填'))
      } else if (!reg.test(value)) {
        callback(new Error('格式错误'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        receiveAccount: [{ required: true, message: '必填', trigger: ['change'] }]
      },
      title: '',
      fullscreenLoading: false,
      multipleTable: [],
      pager: {
        size: 50,
        current: 1,
        total: 0
      },
      toBeOffsetColumns,
      pendingColumns,
      vendorForm: {},
      offsetForm: {
        rules: {},
        tableData1: []
      },
      defaultForm: {
        requestPendingAmount: 0,
        receiveAccount: ''
      },
      ruleForm: {
        tableData1: [],
        rules: {
          requestAmount: [{ required: true, message: '必填', trigger: 'blur' }, { validator: validaterequstFundsAmount, trigger: 'blur' }]
        }
      },
      requestAmount: '',
      AccountList: [],
      multiplePendTable: []
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (val) {
        this._requestFundsView()
        this.changeAccountList()
      } else {
        this.$refs.multipleTable.$refs.table.clearSelection()
        this.requestAmount = ''
        this.$refs.defaultForm.clearValidate()
        this.vendorForm = this.$options.data.call(this).vendorForm
        this.defaultForm = this.$options.data.call(this).defaultForm
        this.pager = this.$options.data.call(this).pager
      }
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {
    handleRequestAmount() {
      let sum1 = 0
      let sum2 = 0
      this.multiplePendTable.map(item => {
        sum1 = FloatAdd(sum1, Math.abs(item.requestAmount))
      })
      this.multipleTable.map(item => {
        sum2 = FloatAdd(sum2, item.requestAmount)
      })
      return FloatSub(sum2, sum1)
    },
    blurRequestAmount(val, row) {
      if (val === 'req') {
        this.$refs.multipleTable.$refs.table.toggleRowSelection(
          row, !!row.requestAmount
        )

        this.multipleTable.map(item => {
          if (item.payableNo === row.payableNo) {
            this.$set(item, 'requestAmount', row.requestAmount)
          }
        })
      } else if (val === 'pend') {
        this.$refs.offsetTable.$refs.table.toggleRowSelection(
          row, !!row.requestAmount
        )
        this.multiplePendTable.map(item => {
          if (item.id === row.id) {
            this.$set(item, 'requestPendAmount', row.requestAmount)
          }
        })
      }
      this.$set(this.defaultForm, 'requestAmount', this.handleRequestAmount())
    },
    selectAllPend(val) {
      if (!val.length) {
        this.offsetForm.tableData1 = this.offsetForm.tableData1.map(item => { return { ...item, requestAmount: 0 } })
      }
    },
    selectAll(val) {
      if (!val.length) {
        this.ruleForm.tableData1 = this.ruleForm.tableData1.map(item => { return { ...item, requestAmount: 0 } })
      }
    },
    stopScroll(evt) {
      evt = evt || window.event
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault()
        evt.stopPropagation()
      } else {
        // IE
        evt.cancelBubble = true
        evt.returnValue = false
      }
      return false
    },
    async changeAccountList() {
      const { datas } = await getVendorAccountListByVendorCode({ vendorCode: this.form.carrierCode })
      this.AccountList = datas
      this.$set(this.defaultForm, 'receiveAccount', datas.length > 0 ? datas[0].cardNo : '')
    },
    select({ rows, row }) {
      const selected = rows.length && rows.indexOf(row) !== -1
      if (!selected) {
        this.$set(row, 'requestAmount', 0)
      }
    },
    resetClick() {
      this.vendorForm = this.$options.data.call(this).vendorForm
      this.handleQuery(1)
    },
    _submit(flag) {
      this.$refs.defaultForm.validate(valid => {
        if (valid) {
          const { cargoOwnerCode, vendorId, carrierCode } = this.form
          const { receiveAccount } = this.defaultForm
          const pendList = this.groupByValue(this.multiplePendTable, 'taxRate').map(item => {
            let sum = 0
            item.data.map(e => {
              sum += Math.abs(e.requestAmount)
            })
            return { ...item, data: Number(sum) }
          })
          const requestList = this.groupByValue(this.multipleTable, 'taxRate').map(item => {
            let sum = 0
            item.data.map(e => {
              sum += Math.abs(e.requestAmount ?? 0)
            })
            return { ...item, data: Number(sum) }
          })
          const bugList = deepClone(pendList)
          if (this.multipleTable.length === 0) {
            this.$message.warning('请至少勾选一条待请款单')
            return
          } else if (this.multipleTable.length > 0) {
            try {
              // 判断能否提交
              this.multipleTable = this.multipleTable.map(item => {
                const obj = bugList.find(e => e.name === item.taxRate) || []
                if (obj.data) {
                  // 有待抵冲单
                  if (FloatSub(item.requestAmount, obj.data) <= 0) {
                    this.$set(item, 'taxRatioRequestFundsAmount', item.requestAmount)
                    this.$set(item, 'inputRequestFundsAmount', 0)
                    obj.data = FloatSub(Number(obj.data), item.requestAmount)
                    this.$set(bugList.find(e => e.name === item.taxRate), 'data', obj.data)
                  } else {
                    this.$set(item, 'taxRatioRequestFundsAmount', obj.data)
                    this.$set(item, 'inputRequestFundsAmount', Number(FloatSub(item.requestAmount, Number(obj.data))))
                    this.$set(bugList.find(e => e.name === item.taxRate), 'data', 0)
                  }
                } else {
                  this.$set(item, 'taxRatioRequestFundsAmount', 0)
                  this.$set(item, 'inputRequestFundsAmount', item.requestAmount ?? 0)
                }
                return { ...item }
              })
              let mulList = this.groupByValue(this.multipleTable, 'taxRate').map(item => {
                let taxSum = 0
                item.data.map(e => {
                  taxSum = FloatAdd(taxSum, Math.abs(e.taxRatioRequestFundsAmount))
                })
                return { ...item, taxRatioRequestFundsAmount: Number(taxSum) ? Number(taxSum).toFixed(2) : 0 }
              })
              mulList = mulList.map(item => {
                requestList.map(e => {
                  if (e.name === item.name && !pendList.find(eee => eee.name === e.name)) {
                    this.$set(item, 'tipRequestAmount', Number(e.data).toFixed(2))
                  }
                  pendList.map(ee => {
                    if (item.name === e.name && e.name === ee.name) {
                      const num = Number(FloatSub(e.data, ee.data)) ? Number(FloatSub(e.data, ee.data)).toFixed(2) : 0
                      this.$set(item, 'tipRequestAmount', num)
                    }
                  })
                })
                return { ...item }
              })

              pendList.map(item => {
                if (!mulList.find(e => e.name === item.name)) {
                  mulList.push({ ...item, taxRatioRequestFundsAmount: 0, tipRequestAmount: -Number(item.data).toFixed(2) })
                }
              })
              // console.log(mulList)
              if (this.multipleTable.find(item => !item.requestAmount)) {
                this.$message.warning('已选择待请款数据存在【请款金额】等于0')
                return
              }
              if (this.multiplePendTable.find(item => !item.requestAmount)) {
                this.$message.warning('已选择待冲抵数据存在【冲抵金额】等于0')
                return
              }

              const confirmText = mulList.map(item => { return `【${item.name}】税率,抵冲金额 ${item.taxRatioRequestFundsAmount}, 请款金额 ${item.tipRequestAmount}` })
              const newDatas = []
              const h = this.$createElement
              for (const i in confirmText) {
                newDatas.push(h('p', null, confirmText[i]))
              }
              if (flag === 'funds') {
                this.$confirm('提示', {
                  title: '提示',
                  message: h('div', null, newDatas),
                  confirmButtonText: '确定',
                  showCancelButton: false,
                  type: 'warning'
                }).then(async() => {
                  try {
                    this.fullscreenLoading = true
                    const params = Object.assign({}, { cargoOwnerCode, vendorId, receiveAccount, carrierCode, toBeOffset: this.multiplePendTable, pendingPayment: this.multipleTable })
                    const { code, msg } = await paymentRequest(params)
                    if (code === 0) {
                      this.$notify({
                        title: msg,
                        message: msg,
                        type: 'success'
                      })
                      this.$emit('input', false)
                      this.$emit('refresh')
                      this.$refs.multipleTable.$refs.table.clearSelection()
                    }
                  } finally {
                    this.fullscreenLoading = false
                  }
                })
              }
            } finally {
              this.fullscreenLoading = false
            }
          }
        }
      })
    },
    async handleQuery(flag) {
      if (flag && flag === 1) {
        this.pager.current = 1
      }
      const { carrierCode, currencyCode, settleEntityCode, requestType } = this.form
      const params = Object.assign({}, { carrierCode, currencyCode, settleEntityCode, requestType }, this.pager, this.vendorForm)
      const { datas: { pendingPaymentByPage: { pager, records = [] }}} = await listPaymentRequestByPage(params)
      this.pager = pager
      // 保留原来的勾选数据
      this.ruleForm.tableData1 = records.map(item => {
        this.multipleTable.map(e => {
          if (e.id === item.id) {
            this.$set(item, 'requestAmount', e.requestAmount)
          }
        })
        return { ...item, inputRequestFundsAmount: 0, taxRatioRequestFundsAmount: 0, requestAmount: item.requestAmount || undefined }
      })

      this.$nextTick(() => {
        this.ruleForm.tableData1.map(item => {
          this.$refs.multipleTable.$refs.table.toggleRowSelection(
            item,
            !!item.requestAmount
          )
        })
      })
    },
    async _requestFundsView() {
      try {
        this.fullscreenLoading = true
        const { carrierCode, currencyCode, settleEntityCode, requestType, settleEntity } = this.form
        this.title = settleEntity
        const params = Object.assign({}, { carrierCode, currencyCode, settleEntityCode, requestType }, this.pager)
        const { datas: { toBeOffset = [], pendingPaymentByPage: { pager, records = [] }}} = await listPaymentRequestByPage(params)
        this.offsetForm.tableData1 = toBeOffset && toBeOffset.length && toBeOffset.map(item => {
          return { ...item, requestAmount: item.requestPendingAmount }
        }) || []
        this.ruleForm.tableData1 = records.length && records.map(item => { return { ...item, taxRatioRequestFundsAmount: 0, inputRequestFundsAmount: 0, requestAmount: undefined } }) || []
        this.pager = pager
        this.$nextTick(() => {
          this.offsetForm.tableData1.map(item => {
            this.$refs.offsetTable.$refs.table.toggleRowSelection(
              item,
              !!item.requestAmount
            )
          })
        })
      } finally {
        this.fullscreenLoading = false
      }
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['requestAmount', 'auditConfirmAmount', 'requestPendingAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },
    groupByValue(arr, value) {
      const map = []
      const result = []
      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i]
        if ((obj[value] || obj[value] === 0) && !map[obj[value]]) {
          result.push(
            {
              name: obj[value],
              data: [obj]
            }
          )
          map[obj[value]] = obj
        } else {
          for (let j = 0; j < result.length; j++) {
            const aj = result[j]
            if (aj.name === obj[value]) {
              aj.data.push(obj)
            }
          }
        }
      }
      return result
    },

    unique(arr, attrName) {
      const res = new Map()
      return arr.filter((a) => !res.has(a[attrName]) && res.set(a[attrName], 1))
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleQuery(0)
    },
    handleSelectionChange(val) {
      this.multipleTable = val
    },
    handlePendSelectionChange(val) {
      this.multiplePendTable = val
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-table .cell {
  /*    height:38px!important;
    line-height: 38px!important; */
  .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
